import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_budget_audit_collect_form_jiulei',
    };
  },
  methods: {
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmAuditCollectExampleController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result.pictureRespVos;
              let files = [];
              if (data && data.length) {
                files = data.map((item) => item.urlAddress);
              }

              this.setValue({ ...res.result, file: files });
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const params = { ...formData };
        // 图片处理
        if (params.file && params.file.length) {
          params.pictureRespVos = [];
          params.file.forEach((item) => {
            params.pictureRespVos.push({
              urlAddress: item,
            });
          });
        }
        let url = '/tpm/tpmAuditCollectExampleController/save';

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmAuditCollectExampleController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
